footer{
    padding: 15px 0;
    background: #000435;
    margin-top: 20px;
    font-size: 15px;
    color:white;
  }

  
  footer .copyright span {
    color: seashell;
  }