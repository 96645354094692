.carousel .carousel-item {
    height: 500px;
  }
  
  .carousel-item img {
      position: absolute;
      top: 0;
      left: 0;
      overflow: shrink;
  }
  .w-100 {
    width: 100% !important;
    height: 75vh;
  }