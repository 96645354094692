.forum-page{
    font-family: 'Poppins', sans-serif;
    }
    .Auth-form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
      }
      
      .Auth-form {
        width: 620px;
        box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
        padding-top: 10px;
        padding-bottom: 20px;
        border-radius: 8px;
        background-color: white;
      }
      
      .Auth-form-content {
        padding-left: 12%;
        padding-right: 12%;
      }
      
      .Auth-form-title {
        text-align: center;
        margin-bottom: 1em;
        font-size: 24px;
        color: rgb(34, 34, 34);
        font-weight: 600;
      }
      
      label {
        font-size: 14px;
        font-weight: 500;
        color: rgb(34, 34, 34);
      }
      button{
        width:200px;
        justify-content: center;
        align-items: center;
      }
      textarea{
        height: 200px;
      }
      .btn .btn-navy{
        background-color: #000435;
        color:white;
      }