.navbar{
    background-color: #ffffff; /* Set to pure white */
    width:100%;
    height:150px;
  }
.header {
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff; 
  font-size: 20px;
}
.navbar-nav>.nav-link {
  position: relative;
}

.navbar-nav>.nav-link {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}

.navbar-nav>.nav-link:hover {
  color: #00e400;
}

.navbar-nav>.nav-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 2px;
  left:50%;
  background-color: #00e400;
  transition: width 0.3s ease, left 0.3s ease;
  transform: scaleX(0); /* Initially scale the line to 0 */
  transform-origin: 0 0;
}

.navbar-nav>.nav-link:hover::after {
  width: 100%;
  left: 0;
  transform: scaleX(1);
}