.card{
    background-color: transparent;
    border: none;
}
p{
    font-size: larger;
}
section{
    padding-top: 10px;
    padding-bottom: 10px;

}